<section class="project secction">
    <div class="">
    <!-- <div class="project__bg"> -->
        <div class="project__container containerPersonal grid">
            <div class="project__data">
                <h2 class="project__title">Tienes un nuevo proyecto?</h2>
                <p class="project__description">Contáctame ahora y tendras un 30% de descuento.</p>
                <a href="https://api.whatsapp.com/send?phone=51980861604&text=Hola%20%F0%9F%91%8B%20me%20gustaria%20realizar%20una%20consulta" target="_blank" class="button button--flex button--white">
                    Contáctame
                    <i class="fas fa-envelope-open-text project__icon button__icon"></i>
                </a>
            </div>
            <img src="assets/img/project.png" alt="" class="project__img">
        </div>
    </div>
</section>