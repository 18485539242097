<section class="about section">
    <h2 class="section__title">Acerca de mi</h2>
    <span class="section__subtitle">Mi Introducción</span>
    <div class="about__container containerPersonal grid">
        <!-- <img src="assets/img/about_gn.jpeg" alt="" class="about__img"> -->

        <div class="imagen-container">
            <img src="assets/img/about_bn.jpeg" alt="Imagen 1" class="imagen" id="imagen">
            <img src="assets/img/about_gn.jpeg" alt="Imagen 2" class="imagen" id="imagen">
            <!-- <img src="assets/img/about_gn.jpeg" alt="Imagen 2" class="imagen" id="imagen2"> -->
        </div>


        <div class="about__data">
            <p class="about__descripcion animated fadeIn">
                Hola soy Alex Laime, analista desarrollador web y mobile con sólida experiencia en backend, frontend y tecnologias de diseño. Me especializo en crear soluciones innovadoras y optimizar interfaces con un enfoque en eficiencia y calidad.
            </p>
            <div class="about__info">
                <div>
                    <span class="about__info-title">+06</span>
                    <span class="about__info-name">Años <br> Experiencia</span>
                </div>
                <div>
                    <span class="about__info-title">+60</span>
                    <span class="about__info-name">Proyectos <br> Completados</span>
                </div>


            </div>
            <div class="about__buttons" style="margin-top: -20px;">
                <a download="" href="https://api.whatsapp.com/send?phone=51980861604&text=Hola%20%F0%9F%91%8B%20me%20gustaria%20realizar%20una%20consulta"  target="_blank" class="button button--flex">
                    Contactar <i class="fas fa-arrow-right button__icon"></i>
                </a>
            </div>
        </div>
    </div>
</section>