<section class="contact section">
    <h2 class="section__title">Contáctame</h2>
    <span class="section__subtitle">Ponte en contacto</span>



    <div class="contact__container containerPersonal grid">
        <div>
            <div class="contact__information">
                <i class="fas fa-phone contact__icon"></i>
                <div>
                    <h3 class="contact__title">LLamame</h3>
                    <span class="contact__subtitle">+51 980861604</span>
                </div>

            </div>

            <div class="contact__information">
                <i class="fas fa-envelope contact__icon"></i>
                <div>
                    <h3 class="contact__title">Correo</h3>
                    <span class="contact__subtitle">alaimes64@gmail.com</span>
                </div>

            </div>

            <div class="contact__information">
                <i class="fas fa-map-marker-alt contact__icon"></i>
                <div>
                    <h3 class="contact__title">Ubicacion</h3>
                    <span class="contact__subtitle">Lima - PERÚ</span>
                </div>

            </div>
        </div>
        <form  class="contact__form grid" [formGroup]="contactForm">

            <div class="contact__content">
                <label for="Nombre" class="contact__label">Nombres y apellidos</label>
                <input type="text" class="contact__input" formControlName="nombres" >
            </div>

            <div class="contact__inputs grid">
                <div class="contact__content">
                    <label for="Nombre" class="contact__label">Correo</label>
                    <input type="text" class="contact__input" formControlName="email" >
                </div>
                <div class="contact__content">
                    <label for="Nombre" class="contact__label">Telefono</label>
                    <input type="email" class="contact__input" formControlName="telefono" >
                </div>
            </div>
           
            <div class="contact__content">
                <label for="Nombre" class="contact__label">Mensaje</label>
                <textarea name="" id="" cols="0" rows="7" class="contact__input" formControlName="mensaje" ></textarea>
            </div>
            <div>
                <a class="button button--flex"  (click)="onSubmit()" style="cursor: pointer;">
                    Enviar mensaje
                    <i class="fas fa-comment-dots button__icon"></i>
                </a>
            </div>
        </form>

    </div>

</section>