
<section class="skills section">
    <h2 class="section__title">Habilidades</h2>
    <span class="section__subtitle">Mi nivel en tecnologias</span>
    <div class="skills__container containerPersonal grid">



        <!-- primera habilidad -->
        <div (click)="cambioOpenLIst('frontend')" [ngClass]="openList ==='frontend' ? 'skills__open': 'skills__close'"
            class="skills__content">
            <div class="skills__header">
                <i class="fas fa-code skills__icon"></i>
                <div>
                    <h1 class="skills__titles">Desarrollador frontend</h1>
                    <span class="skills__subtitle">Mas de 6 años </span>
                </div>

                <i class="fas fa-chevron-down skills__arrow"></i>
            </div>

            <div class="skills__list grid">
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">HTML</h3>
                        <span class="skills__number">90%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__html">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">CSS</h3>
                        <span class="skills__number">80%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__css">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">Javascript</h3>
                        <span class="skills__number">90%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__js">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">Angular</h3>
                        <span class="skills__number">95%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__angular">

                        </span>
                    </div>
                </div>
            </div>

        </div>




        <!-- segunda habilidad -->
        <div (click)="cambioOpenLIst('backend')" class="skill__content "
            [ngClass]="openList ==='backend' ? 'skills__open': 'skills__close'">
            <div class="skills__header">
                <i class="fas fa-server skills__icon"></i>
                <div>
                    <h1 class="skills__titles">Desarrollador backend</h1>
                    <span class="skills__subtitle">Mas de 5 años </span>
                </div>

                <i class="fas fa-chevron-down skills__arrow"></i>
            </div>

            <div class="skills__list grid">
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">PHP</h3>
                        <span class="skills__number">90%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__php">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">NodeJs</h3>
                        <span class="skills__number">80%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__nodejs">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">Firebase</h3>
                        <span class="skills__number">90%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__firebase">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">python</h3>
                        <span class="skills__number">95%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__python">

                        </span>
                    </div>
                </div>
            </div>

        </div>


        <!-- tercera habilidad -->
        <div (click)="cambioOpenLIst('diseño')" class="skill__content "
            [ngClass]="openList ==='diseño' ? 'skills__open': 'skills__close'">
            <div class="skills__header">

                <i class="fas fa-swatchbook skills__icon"></i>
                <div>
                    <h1 class="skills__titles">Diseñador</h1>
                    <span class="skills__subtitle">Mas de 4 años </span>
                </div>

                <i class="fas fa-chevron-down skills__arrow"></i>
            </div>

            <div class="skills__list grid">
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">Figma</h3>
                        <span class="skills__number">90%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__figma">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills_titles">
                        <h3 class="skills__name">Sketch</h3>
                        <span class="skills__number">85%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__sketch">

                        </span>
                    </div>
                </div>
                <div class="skills__data">
                    <div class="skills__titles">
                        <h3 class="skills__name">Photoshop</h3>
                        <span class="skills__number">85%</span>
                    </div>
                    <div class="skills__bar">
                        <span class="skills__percentage skills__photoshop">

                        </span>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>